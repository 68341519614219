// 项目管理
<template>

    <div class="projectManage baseBg">

        <div class="listBox ">
            <el-tabs v-model="activeName"
                     ref="searchTab"
                     class="searchTab"
                     @tab-click="handleClick">
                <el-tab-pane name="进行中全部"
                             v-if="commonJs.isShowBtn(detailRole, 'Tab_进行中全部')">
                    <div class="tabsLabel"
                         @click="commonJs.setTabId(detailRole,'Tab_进行中全部')"
                         slot="label">
                        <el-badge :type="activeName == '进行中全部' ? 'primary' : 'warning'"
                                  :value="counts['进行中全部']"
                                  class="item">
                            进行中全部
                        </el-badge>
                    </div>
                </el-tab-pane>

                <el-tab-pane name="项目规划"
                             v-if="commonJs.isShowBtn(detailRole, 'Tab_项目规划')">
                    <div class="tabsLabel"
                         @click="commonJs.setTabId(detailRole,'Tab_项目规划')"
                         slot="label">
                        <el-badge :type="activeName == '项目规划' ? 'primary' : 'warning'"
                                  :value="counts['项目规划']"
                                  class="item">
                            项目规划
                        </el-badge>
                    </div>
                </el-tab-pane>

                <el-tab-pane name="实施阶段"
                             v-if="commonJs.isShowBtn(detailRole, 'Tab_实施阶段')">
                    <div class="tabsLabel"
                         @click="commonJs.setTabId(detailRole,'Tab_实施阶段')"
                         slot="label">
                        <el-badge :type="activeName == '实施阶段' ? 'primary' : 'warning'"
                                  :value="counts['实施阶段']"
                                  class="item">
                            实施阶段
                        </el-badge>
                    </div>
                </el-tab-pane>

                <el-tab-pane name="竣工验收"
                             v-if="commonJs.isShowBtn(detailRole, 'Tab_竣工验收')">
                    <div class="tabsLabel"
                         @click="commonJs.setTabId(detailRole,'Tab_竣工验收')"
                         slot="label">
                        <el-badge :type="activeName == '竣工验收' ? 'primary' : 'warning'"
                                  :value="counts['竣工验收']"
                                  class="item">
                            竣工验收
                        </el-badge>
                    </div>
                </el-tab-pane>

                <el-tab-pane name="项目核算"
                             v-if="commonJs.isShowBtn(detailRole, 'Tab_项目核算')">
                    <div class="tabsLabel"
                         @click="commonJs.setTabId(detailRole,'Tab_项目核算')"
                         slot="label">
                        <el-badge :type="activeName == '项目核算' ? 'primary' : 'warning'"
                                  :value="counts['项目核算']"
                                  class="item">
                            项目核算
                        </el-badge>
                    </div>
                </el-tab-pane>

                <el-tab-pane name="搁置"
                             v-if="commonJs.isShowBtn(detailRole, 'Tab_搁置')">
                    <div class="tabsLabel"
                         @click="commonJs.setTabId(detailRole,'Tab_搁置')"
                         slot="label">
                        <el-badge :type="activeName == '搁置' ? 'primary' : 'warning'"
                                  :value="counts['搁置']"
                                  class="item">
                            搁置
                        </el-badge>
                    </div>
                </el-tab-pane>
            </el-tabs>

            <div class="tableBox">
                <!-- 搜索条件 -->
                <div class="searchForm mb10">
                    <el-form :model="searchForm"
                             ref="searchForm"
                             :rules="searchFormrules"
                             :inline="true">
                        <el-form-item label="项目名称"
                                      prop="name">
                            <el-input v-model="searchForm.name"
                                      placeholder="请输入项目名称"></el-input>
                        </el-form-item>
                        <el-form-item label="客户名称"
                                      prop="customerName">
                            <customer-or-provider-select-tree ref="selectTree"
                                                              type="CUSTOMER"
                                                              :shouldClean="true"
                                                              @handClear="handClear"
                                                              @treeSelectNodeClick="selectTreeCustClick"></customer-or-provider-select-tree>
                        </el-form-item>
                        <el-form-item label="项目产品"
                                      prop="systemId">
                            <select-project ref="selectProject"
                                            @select="selectSystem"></select-project>
                        </el-form-item>
                        <el-form-item label="是否增补项目"
                                      prop="supplemenFlag">
                            <el-select v-model="searchForm.supplemenFlag"
                                       placeholder="请选择是否增补项目"
                                       clearable>
                                <el-option v-for="item in supplemenFlagOpts"
                                           :key="item.value"
                                           :label="item.label"
                                           :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="合同状态"
                                      prop="contractStatus">
                            <el-select v-model="searchForm.contractStatus"
                                       placeholder="请选择合同状态"
                                       clearable>
                                <el-option v-for="item in contractStatusOpts"
                                           :key="item.value"
                                           :label="item.label"
                                           :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="部门">
                            <dept-select-tree placeholderStr="请选择部门"
                                              :needClean="needClean"
                                              :shouldClean="true"
                                              @handClear="clearDept"
                                              @treeSelectNodeClick="departmentClick"></dept-select-tree>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary"
                                       @click="getList">搜索</el-button>
                            <el-button @click="resetSearchForm">清除</el-button>
                        </el-form-item>

                        <el-button type="success"
                                   class="flr"
                                   @click="addProjectManage">新增项目</el-button>
                    </el-form>
                </div>

                <!-- 列表 -->
                <div class="tableBox">
                    <el-table :data="tableData.data"
                              :height="tableHeight"
                              border
                              stripe>
                        <el-table-column type="index"
                                         label="序号"
                                         align="center"
                                         width="50">
                        </el-table-column>
                        <el-table-column v-for="col in tableData.columns"
                                         align="center"
                                         :prop="col.id"
                                         :key="col.id"
                                         :label="col.label"
                                         :min-width="col.width">
                        </el-table-column>
                        <el-table-column prop="planFinishTime"
                                         label="计划交付日期"
                                         align="center"
                                         min-width="100">
                            <template slot-scope="scope">
                                <span v-if="scope.row.planFinishTime"
                                      :class="{'red': commonJs.compareTime(scope.row.planFinishTime, commonJs.nowTime()) === '>'}">{{scope.row.planFinishTime ? scope.row.planFinishTime.slice(0,10) : '无'}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="id"
                                         label="操作"
                                         align="center"
                                         min-width="140">
                            <template slot-scope="scope">
                                <el-link type="primary"
                                         v-if="commonJs.isShowBtn(detailRole, 'Btn_详情')"
                                         @click="details(scope.row)">详情</el-link>
                                <el-link type="primary"
                                         v-if="commonJs.isShowBtn(detailRole, 'Btn_管理任务')"
                                         @click="taskManage(scope.row)">管理任务</el-link>
                                <template v-if="commonJs.isShowBtn(detailRole, 'Btn_删除')">
                                    <el-popconfirm confirm-button-text="删除"
                                                   cancel-button-text="取消"
                                                   icon="el-icon-info"
                                                   icon-color="red"
                                                   @confirm="del(scope.row)"
                                                   title="是否删除当前项目，删除后将无法找回！">
                                        <el-link type="danger"
                                                 slot="reference">删除</el-link>
                                    </el-popconfirm>
                                </template>
                            </template>
                        </el-table-column>
                    </el-table>
                    <!-- 分页 -->
                    <Pagination :page="searchForm.page"
                                :limit="searchForm.size"
                                :total="pageTotal"
                                @pagination="paginationChange" />
                </div>
            </div>

        </div>
    </div>

</template>

<script>
import CustomerOrProviderSelectTree from "../../components/selectTree/customerOrProviderSelectTree";
import DeptSelectTree from "../../components/selectTree/deptSelectTree";
import selectProject from "@/components/commonSelect/selectProject.vue";
import { Loading } from "element-ui";
import Pagination from "@/components/Pagination"; // 分页
import API from "@/api/projectManage.js";
export default {
    name: "projectManage",

    props: [],

    components: {
        CustomerOrProviderSelectTree,
        DeptSelectTree,
        Pagination,
        "select-project": selectProject,
    },

    data() {
        return {
            activeName: "进行中全部",
            searchForm: {
                name: "", // 项目名称
                customerName: "", // 客户名称
                systemId: "", // 项目产品
                supplemenFlag: "", // 是否增补项目
                contractStatus: "", // 合同状态
                belongDeptId: "", // 归属部门
                stage: "", // 项目进行阶段：1-项目规划,2-实施阶段,3-竣工验收,4-项目核算,5-归档
                status: "", // 项目状态：1-进行中,2-搁置,3-废止,4-完成
                page: 1,
                size: 20,
            },
            pageTotal: 0,
            searchFormrules: {},
            needClean: false, //清空部门
            counts: {
                // 列表数量
                进行中全部: 0, // stageGz
                项目规划: 0, // stageAll
                实施阶段: 0, // stage1
                竣工验收: 0, // stage2
                项目核算: 0, // stage3
                搁置: 0, // stage4
            },
            supplemenFlagOpts: [
                { label: "全部", value: "" },
                { label: "是", value: "是" },
                { label: "否", value: "否" },
            ], // 是否增补项目下拉
            contractStatusOpts: [
                { label: "全部", value: "" },
                { label: "已完成", value: "已完成" },
                { label: "未完成", value: "未完成" },
            ], // 合同状态下拉

            tableData: {
                data: [],
                columns: [
                    { id: "name", label: "项目名称", width: "100" },
                    { id: "customerName", label: "客户名称", width: "100" },
                    { id: "managerName", label: "项目经理", width: "100" },
                    { id: "belongDeptName", label: "归属部门", width: "100" },
                    { id: "money", label: "项目规模（元）", width: "120" },
                    { id: "stage", label: "项目进度", width: "100" },
                    { id: "ssPlan", label: "实施计划", width: "100" },
                    { id: "deviceRate", label: "设备入库率", width: "100" },
                    { id: "projectSystem", label: "项目产品", width: "100" },
                    {
                        id: "supplemenFlag",
                        label: "是否增补项目",
                        width: "100",
                    },
                    { id: "contractStatus", label: "合同状态", width: "100" },
                ],
            },
            detailRole: {}, //权限数据
        };
    },

    created() {},

    /* 组件缓存触发 */
    activated() {
        // 解决从别的模块返回tabId是的上一个模块的问题
        this.$nextTick(() => {
            let tabId =
                this.commonJs.getTabId(
                    this.detailRole,
                    `Tab_${this.activeName}`
                ) || null;
            window.localStorage.setItem("tabId", tabId ? tabId : null);
            this.getList();
        });
        //     this.commonJs.getFistTabId(this.detailRole, [
        //         "Tab_进行中全部",
        //         "Tab_项目规划",
        //         "Tab_实施阶段",
        //         "Tab_竣工验收",
        //         "Tab_项目核算",
        //         "Tab_搁置",
        //     ]);

        //     // 选中第一个tab
        //     this.activeName = this.$refs.searchTab.$children[1].name;
        //     this.handleClick(this.$refs.searchTab.$children[1]);
        //     // 项目管理-列表数量
        //     this.projectManageCount();
        //     // 查询列表
        //     this.getList();
        //     this.commonJs.getCurrentButtonByMenuId(
        //         window.localStorage.getItem("routerId")
        //     );
    },

    mounted() {
        this.commonJs.getFistTabId(this.detailRole, [
            "Tab_进行中全部",
            "Tab_项目规划",
            "Tab_实施阶段",
            "Tab_竣工验收",
            "Tab_项目核算",
            "Tab_搁置",
        ]);

        // 选中第一个tab
        this.activeName = this.$refs.searchTab.$children[1].name;
        this.handleClick(this.$refs.searchTab.$children[1]);
        // 项目管理-列表数量
        this.projectManageCount();
        // 查询列表
        this.getList();
        this.commonJs.getCurrentButtonByMenuId(
            window.localStorage.getItem("routerId")
        );
    },

    methods: {
        // 切换tabs
        handleClick(tab) {
            this.searchForm.stage = "";
            this.searchForm.status = "";
            let data = [
                {
                    label: "项目规划",
                    value: "1",
                    type: "stage",
                },
                {
                    label: "实施阶段",
                    value: "2",
                    type: "stage",
                },
                {
                    label: "竣工验收",
                    value: "3",
                    type: "stage",
                },
                {
                    label: "项目核算",
                    value: "4",
                    type: "stage",
                },
                {
                    label: "归档",
                    value: "5",
                    type: "stage",
                },
                {
                    label: "进行中全部",
                    value: "1",
                    type: "status",
                },
                {
                    label: "搁置",
                    value: "2",
                    type: "status",
                },
                {
                    label: "废止",
                    value: "3",
                    type: "status",
                },
                {
                    label: "完成",
                    value: "4",
                    type: "status",
                },
            ];
            let nowStep = data.filter((item) => item.label === tab.name)[0];
            this.searchForm[nowStep.type] = nowStep.value;
            this.getList();
        },
        // 跳转到管理任务页面
        taskManage(item) {
            this.$router.push({
                path: "/taskManage/itemTask",
                query: {
                    id: item.id,
                    needGoBack: true,
                },
            });
        },
        // 新增项目
        addProjectManage() {
            this.$router.push({
                path: "/projectManage/projectManageDetails",
                query: {
                    status: "新增",
                    id: null,
                },
            });
        },
        //项目客户的选择
        selectTreeCustClick(node) {
            this.searchForm.customerName = node.label;
        },
        //项目客户的清空
        handClear() {
            this.searchForm.customerName = "";
        },
        // 项目产品的选择
        selectSystem(data) {
            this.searchForm.systemId = data.value;
        },
        // 项目管理-列表数量
        projectManageCount() {
            let loading = Loading.service({
                target: document.querySelector(".el-tabs"),
            });
            API.projectManageCount({ order: "DESC" })
                .then((res) => {
                    this.counts = {
                        进行中全部: res.content.stageAll,
                        项目规划: res.content.stage1,
                        实施阶段: res.content.stage2,
                        竣工验收: res.content.stage3,
                        项目核算: res.content.stage4,
                        搁置: res.content.stageGz,
                    };
                    loading.close();
                })
                .catch(() => {
                    loading.close();
                });
        },
        // 查询列表
        getList() {
            let loading = Loading.service({
                target: document.querySelector(".tableBox"),
            });
            API.projectManageList(this.searchForm)
                .then((res) => {
                    loading.close();
                    this.tableData.data = res.content.records;
                    let stageArr = [
                        "项目规划",
                        "实施阶段",
                        "竣工验收",
                        "项目核算",
                        "归档",
                    ];
                    let statusArr = ["进行中", "搁置", "废止", "完成"];
                    this.tableData.data.forEach((item) => {
                        item.stage = stageArr[item.stage - 1];
                        item.status = statusArr[item.status - 1];
                    });
                    this.pageTotal = res.content.total;
                    this.counts[this.activeName] = res.content.total;
                })
                .catch(() => {
                    loading.close();
                });
        },
        //部门的选择
        departmentClick(node) {
            this.searchForm.belongDeptId = node.id;
        },
        //部门清空
        clearDept() {
            this.searchForm.belongDeptId = "";
        },
        // 清空表单
        resetSearchForm() {
            this.$refs.selectTree.$refs.selectTree.cleanSelect();
            this.$nextTick(() => {
                if (this.$refs["searchForm"] !== undefined) {
                    this.$refs["searchForm"].resetFields();
                    // 清除下拉树选择
                    this.customerName = "";
                    this.systemId = "";
                    this.needClean = Math.random();
                    this.$refs.selectProject &&
                        this.$refs.selectProject.cleanSelect();
                    // 查询列表
                    this.getList();
                }
            });
        },

        // 分页改变
        paginationChange(e) {
            this.searchForm.size = e.size;
            this.searchForm.page = e.page;
            this.getList();
        },
        // 查看详情
        details(row) {
            window.localStorage.setItem("projectTab", this.activeName);
            this.$router.push({
                path: "/projectManage/projectManageDetails",
                query: {
                    status: "编辑",
                    id: row.id,
                },
            });
        },
        // 删除
        del(row) {
            let loading = Loading.service({
                target: document.querySelector(".tableBox"),
            });
            API.delProject({
                id: row.id,
            })
                .then(() => {
                    this.$message({ message: "删除成功！", type: "success" });
                    // 查询列表
                    this.getList();
                    loading.close();
                })
                .catch(() => {
                    loading.close();
                });
        },
    },

    computed: {
        tableHeight() {
            return this.commonJs.getTableHeight({ pageOtherHeight: 315 });
        },

        //权限数据
        roleInfo() {
            return this.$store.state.role.roleInfo;
        },
    },

    watch: {
        roleInfo: {
            deep: true,
            immediate: true,
            handler: function (val) {
                this.detailRole = this.commonJs.tabIsAlive(
                    val,
                    "项目管理_列表"
                );
            },
        },
    },
};
</script>

<style lang="scss" scoped>
.projectManage ::v-deep .el-tabs__nav-scroll {
    overflow: visible;
}
.projectManage ::v-deep .el-tabs__nav-wrap {
    overflow: visible;
    height: 40px;
}
</style>
